import Axios from "./Axios";

export default class LogService extends Axios {
  async getAll() {
    return await this.axios.get("/logs");
  }
  async getFile(name) {
    return await this.axios.get(`/log/`+name);
  }
  async deleteFile(name) {
    return await this.axios.delete(`/log/`+name);
  }
  async downloadFile(name) {
    return await this.axios.get(`/download/log/`+name);
  }
  async compressFiles(files) {
    return await this.axios.post(`/log/compress`,files);
  }
}
