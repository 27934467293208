<template>
  <v-card flat>
    <div v-if="!file">
      <v-card-text>
        <v-layout row wrap>
          <!--          <v-flex xs6 v-if="selected.length > 0">-->
          <!--            <v-btn color="primary" block>-->
          <!--              Download-->
          <!--              <v-icon-->
          <!--                  right-->
          <!--                  dark-->
          <!--              >-->
          <!--                download-->
          <!--              </v-icon>-->
          <!--            </v-btn>-->
          <!--          </v-flex>-->
          <v-flex xs12 v-if="selected.length > 0">
            <v-btn color="primary" @click="compressFiles()" block>
              Compactar
              <v-icon
                  right
                  dark
              >
                compress
              </v-icon>
            </v-btn>
          </v-flex>
          <v-flex xs12>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="logs"
                item-key="name"
                loading-text="Estamos buscando seus resultados"
                :loading="loading"
                show-select
            >
              <template v-slot:items="props">
                <td class="text-left">{{ props.item.name }}</td>
                <td class="text-left">{{ props.item.date }}</td>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon
                    v-if="item.ext === '.txt'"
                    medium
                    class="mr-2"
                    title="Visualizar arquivo"
                    @click="showLog(item)"
                >text_snippet
                </v-icon
                >
                <v-btn icon v-bind:href="getHrefDownload(item)" target="_blank">
                  <v-icon
                      medium
                      class="mr-2"
                  >get_app
                  </v-icon
                  >
                </v-btn>

                <v-icon
                    medium
                    class="mr-2"
                    @click="removeFile(item)"
                >delete
                </v-icon
                >
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
    </div>
    <div v-else>
      <v-card-title class="justify-center">
        {{ file.name.toUpperCase() }}
      </v-card-title>
      <v-card-text>
        <v-layout row wrap justify-space-around>
          <v-flex xs4>
            <v-btn @click="returnToList" block color="primary">Voltar</v-btn>
          </v-flex>
          <v-flex xs4 pb-1>
            <v-btn block color="primary" @click="downloadContentInScreen">Download</v-btn>
          </v-flex>
          <v-flex xs12>
            <v-card style="height: 71vh;overflow: auto">
              <v-card-text>
                <p id="content_download" style="white-space: pre-line">{{ file.content }}</p>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
    </div>
  </v-card>
</template>

<script>
import LogService from "@/services/LogService";

export default {
  name: 'ListLogs',
  data: function () {
    return {
      page: 1,
      pageCount: 0,
      selected: [],
      loading: false,
      logs: [],
      file: null,
      total: 0,
      headers: [
        {
          text: "Arquivo",
          value: "name",
          align: "left"
        }, {
          text: "Formato",
          value: "ext",
          align: "left"
        },
        {
          text: "Data",
          value: "date",
          align: "left"
        },
        {text: "Ação", value: "action"}
      ],
      hiden: false
    };
  },
  watch: {
    // page: async function(val) {
    //   const res = await new logservice().getlogs(this.page)
    //   this.logs = res.data.data
    //   this.total = parseInt(res.data.total)
    // }
  },
  mounted() {
    this.getLogs()
  },
  methods: {
    async getLogs() {
      const res = await new LogService().getAll()
      this.logs = res.data
      console.log(this.logs)
      this.total = parseInt(res.data.length)
    },
    async showLog(item) {
      const res = await new LogService().getFile(item.name + item.ext)
      if (res.data)
        this.file = res.data
    },
    returnToList() {
      this.file = null
    },
    downloadContentInScreen() {
      var link = document.createElement('a');
      link.download = this.file.name;
      var blob = new Blob([this.file.content], {type: 'text/plain'});
      link.href = window.URL.createObjectURL(blob);
      link.click();
    }, async downloadInServer(item) {
      const res = await new LogService().downloadFile(item.name + item.ext)
      if (res.data) {
        var dataUri = 'data:application/octet-stream;charset=utf-8,' + encodeURIComponent(res.data);
        var anchor = document.createElement('a');

        anchor.setAttribute('href', dataUri);
        anchor.setAttribute('download', item.name + item.ext);
        anchor.click()
      }
    },
    async removeFile(item) {
      await this.$confirm(
          "Deseja realmente remover o arquivo " + item.name + item.ext,
          async () => {
            console.log("Atualizando ....")
            try {
              this.loading = true
              await new LogService().deleteFile(item.name + item.ext)
              const index = this.logs.indexOf(item)
              if (index > -1) {
                this.logs.splice(index, 1);
              }
            } finally {
              this.loading = false
            }
          }
      )
    },
    getHrefDownload(item) {
      let url = '/update-api/download/log/' + item.name + item.ext
      if (process.env.NODE_ENV !== "production") {
        url = "http://127.0.0.1:3090" + url
      }
      return url
    },
    async compressFiles() {
      try {
        this.loading = true
        const data = await new LogService().compressFiles(this.selected)
        for (let selectedElement of this.selected) {
          if (selectedElement.name + selectedElement.ext === "log.txt") continue
          const index = this.logs.indexOf(selectedElement)
          if (index > -1) {
            this.logs.splice(index, 1)
          }
        }
        this.logs.push(data.data)
      } finally {
        this.loading = false
      }
    }
  }
};
</script>